button {
  border-radius: 12px;
  border: 1px solid var(--not-white);
  cursor: pointer;
  padding: .5rem .5rem;
  font-size: 1rem;
  background-color: transparent;
}

body.dark button {
  color: var(--not-white);
}

button.headerBtn {
  border-radius: 6px;
  border:none;
  padding: 1rem 1rem;
}

button.primaryBtn:hover {
  background-color: blue;
}

button.successBtn:hover {
  background-color: green;
}

button.dangerBtn:hover {
  background-color: red;
}

body.dark button.headerBtn:hover {
  background-color:#282828;
}
body button.headerBtn:hover {
  background-color: var(--light-layer-one);
}

body.dark button:hover {
}