:root {
    --base-light-bg: #ffffff;
    --base-light-txt: #0c0d0e;
    --not-white: #cccccc;
    --light-layer-one: #f6f6f6;
    --dark-layer-one: #282828;
    --dark-layer-two: #3a3a3a;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


body {
  background-color: var(--base-light-bg);
  color: var(--base-light-txt);
  font-size: 18px;
}
@media (prefers-color-scheme: dark) {
  body {
    
  }
}

body.dark{
  background-color: #181818;
  color: #cccccc;
}

body.dark section {
  background-color:#282828;
  padding: 1rem 1rem;
  border-radius: 12px;
  border: 1px solid black;
}


    body.dark section > .card {
        background-color: var(--dark-layer-two);
        color: var(--not-white);
    }

body section {
  background-color:#f6f6f6;
  padding: 1rem 1rem;
  border-radius: 6px;
}

body.dark  input {
  color: var(--not-white);
}

body.dark section input, body.dark section textarea, body.dark section input:focus, body.dark section textarea:focus {
    background-color: var(--dark-layer-one);
    color: var(--not-white);
    border: 1px solid #747474;
}

body.dark section input::file-selector-button {
    background-color: var(--dark-layer-one);
    color: var(--not-white);
}



nav li {
    list-style-type: none;
    display: inline-block;
    margin: 0 .5rem;
}

nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 1rem;
}

body nav {
    border-bottom: 1px solid #f0f0f0;
}

body.dark nav {
    border-bottom: 1px solid #31383d;
    background-color: #181818 !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.text-align-start{
    text-align: start;
}