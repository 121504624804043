div {
}

.act-banner {
    border: none !important;
    margin: 2vh auto;
}

    .act-banner > div {
        width: 100%;
        border-radius: 6px;
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        height: 8vh;
        padding: 1rem 1rem;
    }

body.dark .act-banner > div {
    background-color: var(--dark-layer-one);
}

body .act-banner > div {
    background-color: var(--light-layer-one);
}

#act-banner-text {
    text-align: start;
    padding-left: 5rem;
    margin: 0 0;
}