.toDoList {
  width: 60%;
  margin: 0 auto;
}

.toDoRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1rem 0;
}

.toDoRow > input {
  width: 60%;
  border: none;
  padding: 0 1rem;
  background-color: transparent;
  border-bottom: 1px solid var(--not-white);
  font-size: 1rem;
}

.toDoRow > input:focus {
  border:none;
  outline:none;
  border-bottom: 1px solid var(--not-white);
}

.strikeThrough {
  text-decoration: line-through;
  text-decoration-thickness: 2px;
}