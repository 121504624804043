ul.resumeTasks {
    line-height: 2.7rem;
    margin: 1rem 1rem;
    margin-left: 100px;
    max-width: 800px;
}

ul.resumeTasks > li {
    margin-bottom: .5rem;
}

.employerLocation{
    margin-left: 1rem;
    margin-bottom: 1rem;
}

.employeeTitle{
    margin-left: 1rem;
}